<template>
  <div class="news-info min-main">
    <Header></Header>
    <div class="banner">
      <img
        class="banner-bg min-main"
        src="@/assets/img/pc/newsInfo/banner.png"
      >
      <div class="banner-title">
        <h2>媒体报道</h2>
        <div></div>
      </div>
    </div>
    <main class="width_fixed">
      <div
        class="news-list"
        v-if="!isOpenNews"
      >
        <ul>
          <li
            v-for="(item,index) in showNewsList"
            :key="index"
            @click="handleOpenNews(item)"
          >
            <img :src="item.imgUrl">
            <div class="new-info">
              <h2>{{item.title}}</h2>
              <p class="new-content">{{item.info}}</p>
              <div class="end">
                <div @click="handleOpenUrl(item.url)">
                  来自媒体：
                  <a class="end-name">{{item.name}}</a>
                </div>
                <div class="end-right">
                  <img
                    src="@/assets/img/pc/newsInfo/new_time.png"
                    alt=""
                  >
                  <p>{{item.time}}</p>
                </div>
              </div>

            </div>
          </li>
        </ul>
        <div
          class="look-more"
          @click="handleMore"
        >
          <p class="more-btn">{{isShow?'收起':'查看更多'}}</p>
        </div>
      </div>
      <news-details
        v-else
        :newTitle="selectedItem.title"
        @navBack="isOpenNews = false"
      ></news-details>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/pc/header'
import Footer from '@/components/pc/footer'
import NewsDetails from './newsDetails.vue'

export default {
  data () {
    return {
      isShow: false,
      isOpenNews: false,
      selectedItem: {},
      showNewsList: [],
      newsList: [
        // {
        //   imgUrl: require('@/assets/img/pc/newsInfo/new22.jpg'),
        //   title: '',
        //   info: '',
        //   name: '',
        //   time: '',
        //   url: ''
        // },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new34.png'),
          title: '未讯科技迈入“厚积薄发”新阶段,上下求索不断提升创新力',
          info: '2022年9月，浙江未讯科技有限公司及其旗下信贷品牌“易借速贷”迎来了五周年庆。',
          name: '网易新闻',
          time: '2022/11/25',
          url: 'https://www.163.com/dy/article/HN1DVBVC0553UYC8.html'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new33.png'),
          title: '新时代新征程，易借速贷助力“普惠金融”新篇章',
          info: '2022年是中国向第二个百年奋斗目标迈进的历史交汇点, 具有非凡意义。作为中国互联网金融协会会员单位，浙江未讯科技有限公司也在积极响应国家号召，主动积极拥抱监管，不断探索创新方式，服务金融机构数字化升级。',
          name: '中华网',
          time: '2022/10/21',
          url: 'https://hea.china.com/article/20221021/102022_1162808.html'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new32.png'),
          title: '易借速贷砥砺前行，五年探索，不辱使命',
          info: '2022年9月，浙江未讯科技有限公司及其旗下信贷品牌“易借速贷”迎来了五周年庆。',
          name: '中国商务网',
          time: '2022/9/29',
          url: 'https://page.om.qq.com/page/OdZbwvL2OBqNy4EYAnj0h7Tw0?source=cp_1009'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new31.png'),
          title: '易借速贷近期发现冒充平台进行诈骗的“李鬼”APP',
          info: '近日，网信办集中打击一批“李鬼”式诈骗APP。易借速贷也收到了一些用户的反馈，个别“李鬼”APP，以人工费、保证金等名义强制收费。',
          name: '',
          time: '2022/9/28',
          url: 'http://www.tele.hc360.com/2022/0928/30455.html'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new30.png'),
          title: '守护金融市场秩序，易借速贷提醒广大用户警惕黑产业诈骗、误导！',
          info: '近年来， “修复征信”“化解债务”“债务优化”“停息挂账”等概念甚嚣尘上，一批所谓的“解债机构”通过各类社交平台被推到借款人面前。',
          name: '东方资讯',
          time: '2022/9/8',
          url: 'http://qiye.eastday.com/n34/u1ai1010545.html'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new29.png'),
          title: '易借速贷：用科技手段助力传统金融行业降本增效',
          info: '2022年初，《关于银行业保险业数字化转型的指导意见》正式下发。与此同时，央行等多部门联合印发《金融标准化“十四五”发展规划》，明确了标准化引领金融业数字生态建设、稳步推进金融科技标准建设等；《金融科技发展规划（2022—2025年）》的出台，标志着银行业金融科技从“星星之火”全面迈入“积厚成势”的新阶段。',
          name: '中华网',
          time: '2022-08-19',
          url: 'https://hea.china.com/article/20220819/082022_1126236.html'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new28.png'),
          title: '易借速贷：科技创新驱动金融服务发展',
          info: '作为中国互联网金融协会会员单位，浙江未讯科技有限公司也在积极响应国家号召，拥抱监管，通过隐私安全算法、隐私增强技术等技术手段搭建数据智能体系，主动在自身和助力金融机构数字化转型的实践中实现用户隐私保护。',
          name: '慧聪网',
          time: '2022-08-11',
          url: 'http://www.tele.hc360.com/2022/0811/29238.html'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new27.png'),
          title: '易借速贷反诈专家提醒：谨防“李鬼”APP诈骗',
          info: '近日，网信办集中打击一批“李鬼”式诈骗APP。易借速贷也收到了一些用户的反馈，个别“李鬼”APP，以人工费、保证金等名义强制收费。首先易借速贷不会强制收取任何费用，下载请认准正规渠道，首选官网、官微。',
          name: '中国经济时报',
          time: '2022-07-21',
          url: 'https://www.cet.com.cn/wzsy/kjzx/3211651.shtml'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new26.png'),
          title: '易借速贷：坚决贯彻落实国家发布的金融科技规范健康发展工作方案',
          info: '6月22日，中央全面深化改革委员会第二十六次会议，审议通过了《强化大型支付平台企业监管促进支付和金融科技规范健康发展工作方案》。',
          name: '新浪网',
          time: '2022-06-23',
          url: 'https://news.sina.com.cn/sx/2022-06-24/detail-imizirav0289295.shtml'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new25.png'),
          title: '易借速贷：坚守数字化转型中的隐私保护',
          info: '2015年，五中全会的“十三五”规划建议提出：“实施国家大数据战略，推进数据资源开放共享。”数字化时代的到来，不仅意味着新的发展机遇，也让数据隐私保护成为了新的难题。',
          name: '财经新闻网',
          time: '2022-06-09',
          url: 'http://www.chnews.net/article/202206/551996.html'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new24.jpg'),
          title: '易借速贷提醒：非法投诉、逃废债，害人害己，国家重拳出击，严打“债闹”',
          info: '近日，中办、国办、发改委多部门接连发文，对假借“征信修复”“征信洗白”等名义招摇撞骗，通过虚假宣传、教唆无理申诉、材料造假、恶意投诉等手段骗取钱财或个人信息，严重扰乱市场秩序的市场主体以零容忍态度重拳打击。央视等重磅媒体也密集曝光十余省市合力围剿“债闹”，打击非法代理投诉，监管层层加码。',
          name: '搜狐',
          time: '2022-4-18',
          url: 'https://m.sohu.com/a/538903330_100189455'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new23.jpg'),
          title: '易借速贷：赋能服务新市民，大数据分析+金融反欺诈成当务之急',
          info: '3月初，银保监会与人民银行发布《关于加强新市民金融服务工作的通知》，通知提出要满足新市民在创业、就业、住房、教育、医疗、养老等方面的多元化金融服务需求。而做好这件事，科技赋能金融成为必然。众多新市民群体的金融需求具有小额分散特征，且个人信用记录未必完善，银行只有提供借助大数据分析与各类智能技术，通过更多维度重新勾画他们的信用特征同时设计针对性的金融产品，才能满足真正实现“服务好新市民”的目标。',
          name: '零度悦读',
          time: '2022-4-6',
          url: 'https://baijiahao.baidu.com/s?id=1729351962280146309&wfr=spider&for=pc'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new22.jpg'),
          title: '易借速贷：跟紧国务院金融委释放的重磅信号',
          info: '3月16日，国务院金融稳定发展委员会召开专题会议，会议提到了平台经济治理。会议要求，有关部门要按照市场化、法治化、国际化的方针完善既定方案，坚持稳中求进，通过规范、透明、可预期的监管，稳妥推进并尽快完成大型平台公司整改工作，红灯、绿灯都要设置好，促进平台经济平稳健康发展，提高国际竞争力。',
          name: '新浪',
          time: '2022-3-21',
          url: 'http://vr.sina.com.cn/2022-03-21/doc-imcwiwss7254038.shtml'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new21.jpg'),
          title: '未讯科技：聚焦两会，助力金融行业数字化转型',
          info: '2022年是党的二十大召开之年，也是中国进入全面建设社会主义现代化国家、向第二个百年奋斗目标进军新征程的重要一年。3月4日-3月11日，“两会”在首都北京如期召开。这一年里，金融作为经济活水该如何“引用”发挥最大效能，引发了热烈讨论。',
          name: '凤凰网',
          time: '2022-3-10',
          url: 'https://finance.ifeng.com/c/8EGsDOFh4CS'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new20.jpg'),
          title: '易借速贷安全专家：任何要求打款到私人账户的都是骗子',
          info: '近期，易借速贷接到很多用户反馈，有诈骗分子冒充易借速贷的工作人员，以“利率调整”、“结算错误”等理由要求用户将钱打到指定银行卡，然后再将钱转走。还有一些所谓的“中介”公司，扣押用户的身份证、手机等私人物品，收取服务费、渠道费、违约金等。',
          name: '凤凰网',
          time: '2022-3-4',
          url: 'https://i.ifeng.com/c/8E6n9VkMvb1'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new19.jpg'),
          title: '易借速贷：响应十四五号召，加码数字金融建设',
          info: '截至目前，全国已有福建、广东、陕西、上海、深圳等20余个省市“十四五”金融改革规划登台亮相。从规划来看，各省市都提出了金融业增加值、增加值占GDP 比重、银行业存贷款等预期量化目标，以提升金融业贡献度。这就需要有规模的金融市场主体和完善的金融体系等。',
          name: '网易',
          time: '2022-2-17',
          url: 'https://www.163.com/news/article/H0DM869800019UD6.html'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new18.jpg'),
          title: '易借速贷：坚定践行“数据驱动”的企业核心价值观',
          info: '近日，《金融科技发展规划(2022-2025年)》(以下简称《规划》)正式印发，这是央行发布的金融科技发展第二阶段规划。与三年前的《金融科技(FinTech)发展规划(2019-2021年)》相比，新版《规划》更加强调了“数据”在金融科技发展中的重要地位。',
          name: '网易',
          time: '2022-2-16',
          url: 'https://www.163.com/news/article/H0APG92300019UD6.html'
        },
          {
          imgUrl: require('@/assets/img/pc/newsInfo/new17.jpg'),
          title: '易借速贷出席2022零壹智库数字经济年会，共论商业银行数字化转型',
          info: '2022年1月13日，以“数字发展：构建新格局、构筑新优势”为主题的2022零壹智库数字经济年会在上海隆重召开。易借速贷母公司浙江未讯科技有限公司受邀出席。',
          name: '网易',
          time: '2022-1-17',
          url: 'https://www.163.com/dy/article/GTTH3PO70552QKTG.html'
        },
        {
          imgUrl: require('@/assets/img/pc/newsInfo/new16.jpg'),
          title: '易借速贷助力金融行业新生态',
          info: '12月16日消息，由新浪财经主办的“第14届金麒麟金融峰会”于12月13日-17日隆重举行。中国互联网金融协会会长、中国人民银行原副行长李东荣在论坛上发表了题为《科学妥善应对金融机构数字化转型的几个不平衡问题》的主旨演讲。',
          name: '金融微动态',
          time: '2021-12-20',
          url: 'https://baijiahao.baidu.com/s?id=1719634046606800902&wfr=spider&for=pc'
        },
        {
        imgUrl: require('@/assets/img/pc/newsInfo/new15.jpg'),
        title: '工信部发布金融科技利好消息，易借速贷顺势而为',
        info: '近日，工信部正式复函支持苏州创建区块链发展先导区。这意味着苏州成为全国首个正式获批创建的国家区块链发展先导区。值得注意的是，区块链专栏明确落脚点在金融科技行业。',
        name: '艾瑞网',
        time: '2021-12-9',
        url: 'https://news.iresearch.cn/yx/2021/12/410800.shtml'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new14.png'),
        title: '警企联动反诈骗，防微杜渐筑防线',
        info: '2021年11月19日，浙江霖梓控股有限公司携手杭州地方公安召开“警企联动，反诈同行”——反诈主题宣讲会。',
        name: '中国商务资讯网',
        time: '2021-11-19',
        url: 'http://www.cnswzx.com/a/zhuantizhuanlan/6210.html'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new13.png'),
        title: '未讯科技出席第一届中国信用经济发展峰会暨2021第三届数字信用与风控年会',
        info: '2021年10月21日，“第一届中国信用经济发展峰会暨2021第三届数字信用与风控年会” 在深圳隆重召开。本次峰会聚焦征信、大数据与风控实务，邀请政府、学术界、金融界、技术界专家和高管共同参与，助力深圳数字经济行业创新发展，探寻信用经济与风控的新征程',
        name: '搜狐网',
        time: '2021-11-01',
        url: 'https://www.sohu.com/a/498553421_121236354'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new12.png'),
        title: '易借速贷：赋能区域性银行数字化转型',
        info: '去年，《中共中央 国务院关于构建更加完善的要素市场化配置体制机制的意见》（以下简称《意见》）中，将“数据”作为一种新型生产要素，首次与其他传统要素并列成为第五大生产要素。数据资源作为生产要素，是数字经济时代银行转型的关键抓手',
        name: '站长之家',
        time: '2021-09-06',
        url: 'http://news.iresearch.cn/yx/2021/09/394694.shtml'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new11.png'),
        title: '易借速贷发布关于不法分子冒充平台实施诈骗的声明',
        info: '近日，浙江未讯科技有限公司旗下信贷平台“易借速贷”（以下简称平台）发现多起不法分子冒充该平台员工或合作渠道进行网络贷款诈骗事件，对平台声誉造成严重影响，对广大用户财产安全造成严重威胁和伤害',
        name: '腾讯网',
        time: '2021-08-26',
        url: 'https://new.qq.com/rain/a/20210826A0F1JP00'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new10.png'),
        title: '易借速贷母公司未讯科技正式成为中国互联网金融协会会员',
        info: '近日，中国互联网金融协会向浙江未讯科技有限公司（前身为霖梓科技，以下简称未讯科技）颁发了会员证书，宣告未讯科技正式成为协会一员。未讯科技有关负责人表示：“很荣幸加入中国互联网金融协会，未讯科技将自觉遵守中国互联网金融协会的章程',
        name: '站长之家',
        time: '2021-08-19',
        url: 'https://www.chinaz.com/2021/0819/1293176.shtml'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new9.png'),
        title: '易借速贷安全性获认可：平台完成“国家信息系统安全等级保护三级认证”',
        info: '日前，易借速贷母公司浙江未讯科技有限公司（前身为霖梓科技，以下简称未讯科技）荣获由公安部核准颁布的“信息系统安全等级保护三级认证”。该认证意味着未讯科技旗下包括易借速贷在内的产品在物理安全、网络安全等数十个方面达到国家信息等级保护三级认证标准，能够为用户提供可靠、安全的服务',
        name: '趣财经网',
        time: '2021-08-19',
        url: 'https://baijiahao.baidu.com/s?id=1708486892062504095&wfr=spider&for=pc'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new1.jpg'),
        title: '易借速贷：建设数据安全体系，推动行业良性发展',
        info: '第十三届全国人民代表大会常务委员会第二十九次会议表决通过《中华人民共和国数据安全法》，将于2021年9月1日起施行。在《数据安全法》出台之前，缺乏统一标准，数据管理也不够完善。随着近年来',
        name: '艾瑞网',
        time: '2021-06-30',
        url: 'https://news.iresearch.cn/yx/2021/06/382948.shtml'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new2.jpg'),
        title: '易借速贷用专业保护消费者合法权益',
        info: '近年来，医美行业发展迅速，越来越多年轻人为了更好的展示自己，选择通过医美行业实现变美心愿。与此同时，金融行业也推出了大批美分期、美容贷服务，但由于行业不成熟，各种借贷乱象频繁发生。',
        name: '华夏信息网',
        time: '2021-06-22',
        url: 'https://www.mouldu.com/newslist/0622113147.html'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new3.jpg'),
        title: '易借速贷：倡导理性消费，打造更有温度的品牌',
        info: '随着经济快速发展，人们的消费观念也在不断发生变化，消费结构改善优化，尤其在后疫情时代，国民消费已成为国内经济稳定发展的基石。但在促进消费的同时，社会上也出现了金钱攀比、鼓吹无限制消费等不良现象，尤其在年轻人中间，炫富的风气蔓延。在众多信息诱惑下，年轻人甚至不惜透支消费能力，透支自己的信用，这不但会造成个人的信用危机，也为各种金融诈骗提供了契机，长此以往更是会影响到金融安全。',
        name: '艾瑞网',
        time: '2021-06-08',
        url: 'https://news.iresearch.cn/yx/2021/06/378886.shtml'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new4.jpg'),
        title: '易借速贷以安全为基，筑建信贷风控防护墙',
        info: '最近几年，互联网金融进入高速发展时期，经历了野蛮生长期之后，互联网金融开始进入严格的全面监管期。随着各地监管细则陆续出台，国家监管部门对互联网金融的支持也转入到实际行动中，为互联网金融提供了切实的政策依据，助力互联网金融向着规范化、绿色可持续化的良性方向发展。',
        name: '艾瑞网',
        time: '2021-05-27',
        url: 'https://news.iresearch.cn/yx/2021/05/376942.shtml'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new5.jpg'),
        title: '易借速贷：聚焦科技发展，合作共赢新生态',
        info: '数字金融时代已加速到来，金融科技作为推动行业发展的新引擎，必将助力金融行业高质快速地实现数字化转型。未讯科技作为成熟的金融科技平台，致力于以科技推动金融服务业的数字化升级，携手合作伙伴为消费者提供普惠、绿色、可持续的服务。',
        name: '艾瑞网',
        time: '2021-05-19',
        url: 'https://news.iresearch.cn/yx/2021/05/375192.shtml'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new6.jpg'),
        title: '助力金融业规范健康发展，易借速贷全面纳入央行征信系统',
        info: '在金融科技飞速发展的今天，花呗、借呗、白条等各类消费金融衍生品层出不穷，给消费者带来种类繁多的信贷选择，个人信用的重要性也随之逐渐提升。在国家互联网信用体系建设不断推进的大背景下，互联网金融机构和消费金融机构也不断加强信用信息的共享与合作，支持互联网金融专项整治和互联网信用体系建设，防范金融风险，更好的服务用户。',
        name: '壹点经济',
        time: '2021-05-06',
        url: 'https://baijiahao.baidu.com/s?id=1698978775120188921'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new7.jpg'),
        title: '易借速贷倡导绿色发展，用公益行动反哺社会',
        info: '对于一家互联网金融平台来说，倡导绿色可持续发展至关重要。只有在严格遵守国家法律法规的前提下谋求发展，才能成为一个令人信服的多元化的金融科技开放平台。易借速贷就是这样一个可以为广大用户提供快捷、安全、高效的互联网金融服务的平台。易借速贷通过技术创新不断发展和革新金融服务，有效保障金融科技下的信贷供给，构建可持续发展的生态金融圈。',
        name: '太平洋财富网',
        time: '2021-04-22',
        url: 'http://cy.pcfortune.com.cn/cyxw/2021/0422/56381.html'
      }, {
        imgUrl: require('@/assets/img/pc/newsInfo/new8.jpg'),
        title: '明确年化利率，易借速贷拥抱数字经济潮流',
        info: '近日，央行发布新的贷款政策，为维护贷款市场竞争秩序，保护金融消费者的合法权益，要求在金融市场，所有机构的贷款产品应该明示贷款年化利率。央行的新政令放贷金融机构与借贷人之间的权利约束更加有效，尤其是借贷人的权益能得到最大限度的保护。',
        name: '艾瑞网',
        time: '2021-04-12',
        url: 'https://news.iresearch.cn/yx/2021/04/369053.shtml'
      }]
    }
  },
  watch: {},
  mounted () {
    this.showNewsList = this.newsList.slice(0, 4)
  },
  methods: {
    handleOpenNews (item) {
      this.selectedItem = item
      this.isOpenNews = true
      // this.$router.push({ name: 'newsDetails', params: { title: item.title } })
    },
    handleOpenUrl (url) {
      window.open(url)
      this.stopPropagation();
    },
    handleMore () {
      this.isShow = !this.isShow
      this.$nextTick(_ => {
        this.showNewsList = this.isShow ? this.newsList : this.newsList.slice(0, 4)
      })
    },
    stopPropagation (e) {
      e = e || window.event;
      if (e.stopPropagation) { //W3C阻止冒泡方法  
        e.stopPropagation()
      } else {
        e.cancelBubble = true //IE阻止冒泡方法  
      }
    }

  },
  components: {
    Header,
    Footer,
    NewsDetails
  }
}

</script>

<style lang="less">
.news-info {
  .banner {
    position: relative;
    justify-items: center;
    .banner-bg {
      left: 0;
      top: 0;
      height: auto;
    }
    &-title {
      width: 1300px;
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translateX(-50%);
      h2 {
        font-size: 32px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 45px;
        letter-spacing: 2px;
      }

      div {
        margin-top: 20px;
        width: 60px;
        height: 3px;
        background: #ffffff;
      }
    }
  }
  main {
    overflow: hidden;
    margin-top: 40px;
    .look-more {
      margin: 0px auto;
      width: 170px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 142px;
      margin-bottom: 80px;
      font-size: 16px;
      font-weight: 400;
      color: #4f4f4f;
      line-height: 22px;
      border: 1px solid #999;
      cursor: pointer;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
    }
    .look-more:hover {
      cursor: pointer;
    }
    ul {
      margin: 0px auto;
    }
    li {
      width: 1260px;
      height: 240px;
      margin: 0px auto;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(160, 160, 160, 0.09);
      display: flex;
      margin-bottom: 6px;
      cursor: pointer;
      img {
        width: 250px;
        height: 180px;
        margin-left: 10px;
        margin-top: 30px;
      }
      .new-info {
        flex: 1;
        position: relative;
        margin-top: 35px;
        margin-left: 30px;
        padding-right: 90px;
        font-size: 16px;
        font-weight: 400;
        color: #4f4f4f;
        line-height: 22px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
        h2 {
          font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
          font-size: 20px;
          font-weight: 500;
          color: #1e1f20;
          line-height: 28px;
        }
        .new-content {
          margin-top: 8px;
        }
        .end {
          display: flex;
          width: 880px;
          justify-content: space-between;
          color: #999;
          height: 22px;
          position: absolute;
          bottom: 40px;
          &-name {
            color: #4680f7;
          }
          &-name:hover {
            cursor: pointer;
          }
          &-right {
            display: flex;
            align-items: center;
            img {
              margin-top: 0px;
              margin-left: 0px;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}
</style>
